.container {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}
.h4 {
  margin-top: 20px;
  text-decoration: underline;
  font-weight: 500;
  color: #ea5d26;
  text-align: center;
}
.p {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0 5px 60px;
  color: black;
  font-size: large;
}

.how {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0 0 10px;
  color: black;
  font-size: large;
}
.buttonCall {
  margin-top: 20px;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  padding: 5px;
  background-color: #305c3f;
  color: #fff;
  border: 2px solid transparent;
  transition: background-color 0.3s ease;
}

.buttonCall:hover {
  background-color: #ea5d26;
  color: #fff;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #ea5d26;
}
.callingImg {
  height: 200px !important;
}
.what {
  margin: 30px 0 20px 0;
  text-align: center;
  text-decoration: underline;
}

.ul {
  margin-top: 20px;
  text-align: start;
}
.gainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.advance {
  color: white;
  background-color: #ea5d26;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.advance:hover {
  display: inline-block;
  text-align: center;
  color: #ea5d26;
  background-color: aliceblue;
  border: #ea5d26 solid 2px;
  border-radius: 30px;
}

@media (max-width: 767px) {
  .container {
    margin-top: 0;
  }

  .p {
    display: flex;
    justify-content: flex-start;
    margin: 5px 0 5px 20px;
    color: black;
    font-size: large;
  }

  .buttonCall {
    width: 40%;
    margin: 20px 0 20px 30px;
  }

  .button:hover {
    background-color: #ea5d26 !important;
    color: #fff !important;
  }
  .advance {
    margin-bottom: 90px;
  }
}

/* tablet media queries */
@media (max-width: 991px) {
  .container {
    overflow-y: auto;
  }
  .buttonCall {
    width: 40%;
  }

  .p {
    display: flex;
    justify-content: flex-start;
    margin: 10px 0 0 20px;
    line-height: 1.5;
  }

  .description {
    margin: 10px 0 0 30px;
    padding-left: 5px;
  }

  .how {
    margin-top: 15px;
  }

  .buttonCall {
    margin-top: 20px;
  }

  .what {
    margin: 20px 0 10px 0;
  }
}
