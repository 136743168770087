.homeContainer {
  overflow-x: hidden!important;
}

.containerImg {
  position: relative;
  width: auto;
  height: 4%;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: absolute;
  top: 50%;
  left: 3%;
  transform: translate(0, -50%);
  padding: 20px;
  color: white;
  text-align: start;
}
.content p {
  color: whitesmoke;
}
.paragraph1 {
  margin-top: 20px;
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 20px;
}

.h6 {
  font-size: 20px !important;
  font-weight: 200;
  background-blend-mode:color-burn;
  color: rgb(200, 239, 215)!important;
}
.linkDiv {
  margin-top: 30px;
  gap: 10px;
  margin-left: 50px;
}

.LinkContact {
  width: 200px;
  height: 50px;
  border-radius: 10px;
  padding: 10px;
  background-color: #ea5d26;
  color: #fff;
  border: 1px solid #ea5d26;
  transition: background-color 0.3s ease;
}

.LinkContact:hover {
  background-color: white;
  color: #ea5d26;
  box-shadow: 0 0 0 1px #305c3f, 0 0 0 4px #ea5d26;
}

.LinkRefer {
  margin-top: 20px;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  padding: 5px;
  background-color: white;
  color: #ea5d26;
  border: 2px solid #ea5d26;
  transition: background-color 0.3s ease;
  margin-left: 20px;
}

.LinkRefer:hover {
  background-color: #ea5d26;
  color: #fff;
  box-shadow: 0 0 0 1px #305c3f, 0 0 0 4px #ea5d26;
}

.buttonContact:hover {
  background-color: #ea5d26;
  color: #fff;
}

.button {
  color: white;
  border: 1px solid wheat;
  border-radius: 5px;
  background-color: #ea5d26;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 30px;
}

.button:hover {
  background-color: #305c3f;
  color: #fff;
}

.h5 {
  font-weight: 600;
  color: #ea5d26;
  text-align: center;
}

.h2 {
  font-weight: 700;
  color: black;
  text-align: center;
}

.benefits {
  display: flex;
  justify-content: center;
  background-color: #ededed;
  height: 100px;
  border-radius: 10px;
}

.para {
  color: black;
  margin-top: 25px;
  font-size: large;
}

.numbers {
  width: 35px;
  height: 35px;
  background-color: #ea5d26;
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  margin: 20px 10px 0 10px;
}

.overlayContainer {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.img-fluid {
  width: 100%;
  height: auto;
  max-width: none;
}
@media (max-width: 320px) {
  .rowColumns {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .content {
    padding: 1px;
    text-align: start;
    height: auto;
  }
}

@media (max-width: 768px) {
  .rowColumns {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .content {
    padding: 1px;
    text-align: start;
    height: auto;
  }

  .content .h6 {
    margin-top: 10px;
    font-size: 12px !important;
  }
  .paragraph1 {
    font-weight: 800;
    color: whitesmoke;
    line-height: 1.5;
    font-size: large;
  }
  .paragraph2 {
    margin-top: 10px;
    padding-right: 20px;
    font-size: smaller;
  }
  .linkDiv {
    gap: 20px !important;

    text-align: start;
    margin-left: 0;
    margin-top: 15px;
  }

  .LinkContact,
  .LinkRefer {
    margin-left: 10px;
    padding: 5px !important;
    border-radius: 5px;
  }
  .LinkContact:hover {
    background-color: white;
    padding: 5px !important;
    height: 20px !important;
    border: #ea5d26 solid 1px;
    width: auto !important;
    color: #ea5d26;
  }

  .LinkRefer:hover {
    background-color: #ea5d26;
    padding: 5px !important;
    height: 20px !important;
    border: #ea5d26 solid 1px;
    width: auto !important;
    color: #fff;
  }

  .content .buttonContact,
  .content .buttonRefer {
    padding: 5px;
    font-size: 8px;
    gap: 10px;
  }

  .benefits {
    height: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
  }
}

.returnToTopButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ea5d26;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.returnToTopButton:hover {
  background-color: #305c3f;
}
