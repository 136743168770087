.container {
  padding: 10px;
  margin-top: 50px;
}
.row{
    padding-left: 20px;
}

.support {
  color: #ea5d26;
  font-size: larger;
}

.explanation {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
}
.column1 {
  display: flex;
  flex-direction: column;
}
.explore:hover{
  display: inline-block;
  margin: 0 0.2px;
  text-align: center;
  animation-duration: 10s; 
}

.column1 {
  
  font-weight: 200;
}

.column1 h4 {
  color: whitesmoke;
  width: max-content;
  border: 1px solid #ea5d26;
  border-radius: 10px;
  background-color: #ea5d26;
  font-size: 16px;
  margin-top: 20px;
  padding: 10px 20px 10px 20px;
}

/* col 2 */
.column2 {
  position: relative;
}

.imageSquare {
  position: absolute;
  top: 45%;
  left: 60%;
  transform: translate(-50%, -50%);
  width: calc(30% + 10px); 
  height: calc(100% + 42px);
  border: 5px solid #ea5d26;
  box-sizing: border-box;
  z-index: -1;
}

.img-fluid {
  width: 100%;
  height: auto;
  max-width: none;
}

@media (max-width: 768px) {
  .row {
    margin-top: 0;
    flex-direction: column;
  }
  .column1 p {
    padding: 10px;
  }
  .column1 h4 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .column2 {
    margin-top: 30px;

    position: relative;
  }
  .imageSquare {
    padding: 20px;
    position: absolute;
    top: 45%;
    left: 70%;
    transform: translate(-50%, -50%);
    width: calc(50% + 30px); 
    height: calc(100% + 26px); 
    border: 5px solid #ea5d26; 
    z-index: -1;
  }
  .explore:hover{
    display: inline-block;
    text-align: center;
    color: #ea5d26;
    background-color: aliceblue;
    border: #ea5d26 solid 2px;
  
  }
 
}
