/* .h4 styles */
.h4 {
  font-weight: 600;
  color: #ea5d26;
  text-align: center;
}

/* .span styles */
.span {
  padding-right: 10px;
}

/* .imageDiv styles */
.imageDiv {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .partnerImg styles */
.partnerImg {
  max-width: 100%;
  height: auto;
}

/* .partnership styles */
.partnership {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: large;
}

/* .p styles */
.p {
  color: black;
  margin: 0;
  text-align: center;
}

/* .link styles */
.link {
  color: #ea5d26;
  text-decoration: underline;
}
