.mainContainer{
  overflow-y: hidden !important;
  overflow-x: hidden!important;
}

.blogContainer {
  padding:10px;
  display: flex;
  flex-wrap: wrap;
}
.card {
  transition: transform 0.40s ease-in-out;
}

.card:hover {
  transform: scale(1.01);
}

.heading {
  color: #305c3f;
  text-decoration: none;
  font-size: 24px;
}
.button {
  display: flex;
  background-color: #ea5d26;
  margin-top: 20px;
  color: wheat;
}

.buttonCollapse {
  display: flex;
  background-color: #305c3f;
  margin-top: 20px;
  color: wheat;
}

@media (max-width: 768px) {
  .blogContainer {
    padding: 10px!important;
    display: flex;
    flex-wrap: wrap;
  }
  .cardWrapper {
    flex-basis: calc(100% - 20px);
  }
  .card:hover {
    transform: scale(1.01);
  }
}
