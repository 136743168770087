.mainContainer{
  overflow-y: hidden !important;
  overflow-x: hidden!important;
}

.content {
  overflow-y: auto;
}

/* question */
.h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ea5d26;
  margin-top: 15px;
  font-weight: 600;
}

/* paragraphs */
.divPara {
  margin: 15px 0 20px 0;
}

.para {
  color: black;
  margin-left: 40px;
}

/* image div */

.imag {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

/* accordion */
.accordion {
  margin-bottom: 70px;
}

/* Media queries for large screens (e.g., laptops/desktops) */
@media (min-width: 992px) {
  .h3 {
    font-size: 32px;
    margin-top: 40px;
  }
}

/* Media queries for medium screens (e.g., tablets) */
@media (min-width: 768px) and (max-width: 991px) {
  .h3 {
    font-size: 28px;
    margin-top: 30px;
  }
}

/* Media queries for small screens (e.g., smartphones) */
@media (max-width: 767px) {
  .h3 {
    font-size: 24px;
    margin-top: 20px;
  }
  .para {
    margin-left: 20px;
    line-height: 1.7;
  }
  .imagContainer {
    margin: 20px;
  }
}
