.navbar {
  margin: 0 !important;
  height: 80px;
  width: 100% !important;
}

.centering {
  display: flex;
  justify-content: center;
}

.imageDiv {
  margin: 30px 0 0 50px !important;
  display: flex;
  justify-content: center;
}

.navbarCollapse {
  color: #305c3f !important;
  font-weight: 600;
  font-size: medium !important;
  margin-left: 150px;
}
.navbarCollapse a:hover {
  color: #ea5d26;
}
.home {
  color: #305c3f !important;
  font-weight: 800;
}
.home:hover {
  color: #ea5d26 !important;
  font-weight: 800;
}

.button {
  width: 30;
  font-size: medium !important;
}

.button {
  width: auto;
  height: 40px;
  border-radius: 10px;
  padding: 5px 10px 5px 10px;
  background-color: #305c3f;
  color: #fff;
  border: 2px solid transparent;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #ea5d26;
  color: #fff;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #ea5d26;
}

.greenMenuIcon {
  color: #305c3f;
}
.dropdownMenu {
  font-size: medium !important;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .navbar {
    height: auto;
    margin: 0;
  }
  .imageDiv {
    margin-top: 0 !important;
    justify-content: flex-start;
  }
  .imageCon {
    margin: 0;
    width: 60px !important;
  }

  .navbarCollapse {
    color: #305c3f !important;
    font-weight: 600;
    font-size: medium !important;
    margin-left: 0px;
    justify-content: flex-start !important;
    font-size: 12px !important;
    line-height: 0 !important;
  }
  .button {
    width: fit-content;
    font-size: 12px !important;
  }

  .dropdownMenu {
    width: fit-content;
    font-size: 12px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .navbar {
    height: 160% !important;
  }
}
