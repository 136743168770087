.container {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}
.h4 {
  margin-top: 20px;
  font-weight: 600;
  color: #ea5d26;
  text-align: center;
}

.p {
  color: black;
  margin-bottom: 5px;
  text-align: start;
}

.secContainer {
  flex-direction: column;
  margin-left: 0;
  align-items: flex-start;
}

.button {
  margin-top: 20px;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  padding: 5px;
  background-color: #305c3f;
  color: #fff;
  border: 2px solid transparent;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #ea5d26;
  color: #fff;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #ea5d26;
}

.imageHolder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
  max-width: 100%;
  max-height: 100%;
}

.containerB {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.advance {
  margin-top: 10px;
  color: white;
  background-color: #ea5d26;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.advance:hover {
  display: inline-block;
  text-align: center;
  color: #ea5d26;
  background-color: aliceblue;
  border: #ea5d26 solid 2px;
  border-radius: 30px;
}

@media (max-width: 767px) {
  .container {
    padding: 10px;
  }

  .imageHolder {
    margin-top: 20px;
  }

  .button {
    margin: 10px auto 0;
  }
  .button:hover {
    background-color: #ea5d26 !important;
    color: #fff !important;
  }
  .buttonAdvance {
    margin-bottom: 30px;
  }
}
