.ImageContainer {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh; 
}

.ImageContainer img {
  background-attachment: fixed;
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  animation: zoomInOut 25s infinite alternate;
}

.carouselIndicatorsButton {
  background-color: #ea5d26;
  color: white;
  border-radius: 50%;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

/* Media queries for responsiveness */
@media (max-width: 320px) {
  .ImageContainer {
    height: auto;
  }
  .ImageContainer img {
    animation: zoomInOut 15s infinite alternate;
    filter: blur(0.2px);
  }
}
@media (max-width: 768px) {
  .ImageContainer {
    height: 45vh;
  }
  .ImageContainer img {
    animation: zoomInOut 15s infinite alternate;
    filter: blur(0.2px);
  }
  .btnNext {
    margin-right: 20px;
  }
  .carouselIndicatorsButton {
    width: 35px;
    height: 35px;
  }
}

@media (min-width: 1200px) {
  .ImageContainer {
    height: 91vh;
  }
}
