.accordion {
  margin: 30px 0 20px 0;
  padding: 60px;
  border-radius: 60px 0 60px 0px;
}

.sectionHeading {
  text-align: center;
  font-size: 22px;
  font-weight: 900;
  color: #305c3f;
  margin-bottom: 15px;
}

.accordionItem {
  margin-bottom: 20px;
  border-radius: 10px;
}

.accordionHeader {
  border: none!important;

  padding: 10px;
  cursor: pointer;
}

.accordionBody {
  padding-left: 30px;
  margin-left: 10px;
  border: none;
}

.accordionButton {
  font-weight: 800;

  border: none!important;
  background-color: transparent !important;
}

.plusIcon {
  font-size: 22px;
  margin-right: 10px;
}

/* small screen */
@media (max-width: 767px) {
  .accordion {
    padding: 20px 20px 0 20px;
    margin-top:auto !important;
    width: 100% !important;
  }

  .accordionItem {
    margin-bottom: 20px;
    border-radius: 20px;
  }
  .accordionBody {
    padding-left: 1px;
    border: none;
    font-weight: 200;
    font-size: medium;
  }
}
