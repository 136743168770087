.overlayContainer {
  position: relative;
}

.overlayContent {
  position: relative;
  width: 100%;
}

.overlayImageContainer {
  
  position: relative;
}

.overlayImage {
  height: 40vh !important;
 
  width: 100%;
}

.overlay {

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlayText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  width: 90%; 
}

.overlayText p {
  margin-bottom: 20px;
}

.overlayText button {
  color: white;
  background-color: #ea5d26;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.overlayText button:hover {

  display: inline-block;
  text-align: center;
  color: #ea5d26;
  background-color: aliceblue;
  border: #ea5d26 solid 2px;
  border-radius: 30px;
  /* background-color: #305c3f; */
}

/* Responsive Styling */
@media (max-width: 768px) {
  .overlayText {
    width: 100%; 
    padding:10px;
    left: 0;
    transform: translateY(-50%);
  }

  .explore:hover{
    display: inline-block;
    text-align: center;
    color: #ea5d26;
    background-color: aliceblue;
    border: #ea5d26 solid 2px;
  
  }
  

  .overlayContent {
    height: 200px;
  }
  .overlayImageContainer {
    height: 50%;
  }
  .overlayImage {
    height: 200%!important;
  }

  .overlay {
    height: 200% !important;
  }
}

@media (max-width: 576px) {
  .overlayText p {
    font-size: 14px;
  }
}
