.mainContainer{
  overflow-y: hidden !important;
  overflow-x: hidden!important;
}

.content {
  min-height: calc(100vh - 60px);
 

}

.imageDiv {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageDiv img {
  max-width: 100%;
  height: auto;
}

.h4 {
  font-weight: 600;
  color: #ea5d26;
  text-align: center;
}

.p {
  margin: 10px 0 30px 0;
  font-size: large;
  color: black;
  text-align: center;
}
.queries {
  margin: 10px 0 20px 0;
  font-size: large;
  color: black;
  text-align: center;
}

/* .button {
  color: white;
  border: 1px solid #ea5d26;
  border-radius: 10px;
  background-color: #305c3f;
  padding: 6px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;
} */

.button {
  width: 200px;
  height: 50px;
  border-radius: 10px;
  padding: 10px;
  background-color: #305c3f;
  color: #fff;
  border: 2px solid transparent;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #ea5d26;
  color: #fff;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #ea5d26; 
}


@media (max-width: 767px) {
  .content {
    padding: 10px;
  }
  .button {
    margin: 10px 0 15px 0;
  }
  .button:hover {
    background-color: #ea5d26;
    border: white solid 2px;
    color: #fff;
    padding: 10px !important;
    align-self: center;
    height: 50px !important;
    width: 50% !important;
  }
  
}
