.footer {
  bottom: 0;
  width: 100%;
  background-color: black;
  text-align: center;
  padding: 10px 0;
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  list-style-type: disc;
  color: white;
  font-size: medium;
  padding-left: 20px;
  text-decoration: none;
}
.list:hover {
  color: #ea5d26;
}
.list a:hover {
  color: #ea5d26 !important;
}

.a {
  text-decoration: none;
  margin-bottom: 10px;
}

.socials {
  display: flex;
  justify-content: flex-start;
}

.socialIcons {
  width: 100%;
}

.para {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: large;
}

.heading,
.headings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  color: #ea5d26;
  font-size: larger;
}

.socialsImg {
  text-align: center;
  margin-bottom: 10px;
}

.Icon {
  width: auto;
  height: auto;
  background-color: whitesmoke !important;
  padding: 5px;
  border-radius: 50%;
}
.Icon:hover {
  background-color: #ea5d26 !important;
  animation: Shake 0.5s linear infinite;
}

@keyframes Shake {
  0% {
    transform: rotate(5deg);
  }

  25% {
    transform: rotate(-6deg);
  }

  50% {
    transform: rotate(5deg);
  }

  75% {
    transform: rotate(-6deg);
  }

  100% {
    transform: rotate(5deg);
  }
}

/* Responsive Styling */
@media (max-width: 768px) {
  .footer {
    padding: 10px;
  }
  .Imagediv {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .heading,
  .headings {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .list {
    list-style-type: disc;
    color: white;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: flex-start;
  }

  .listItems {
    margin-bottom: 10px;
  }
  .list a:hover {
    color: #ea5d26 !important;
  }

  .para {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }

  .socialIcons {
    justify-content: space-evenly;
  }
}
.copyright {
  font-size: small;
}

.copyright:hover {
  color: whitesmoke !important;
}

/* For larger screens */
@media (min-width: 992px) {
.socialIcons{
  justify-items: flex-start;
  flex-wrap: wrap;
}
}