.section {
}

.h5 {
  color: #ea5d26;
  font-weight: 400;
  font-size: 24px;
  margin: 10px 0 10px 0;
}

.benefits {
  display: flex;
  align-items: center;
  height: 100px !important;
  color: black;
  background-color: #ededed;
  border-radius: 10px;
  transition: background-color 0.3s; 
  overflow: hidden;
}

.benefits:hover,
.benefits:active {
  background-color: #ea5d26;
  transform: skewX(10deg); 
}


.benefits:hover {
  transition: transform 0.3s; 
}

.para {
  color: black;
  font-size: medium;
}

.numbers {
  width: 35px;
  height: 35px;
  background-color: #ea5d26;
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  margin-right: 10px; /* Add margin to separate the span and p */
}

@media (max-width: 768px) {
  .rowColumns {
    height: auto !important;
    flex-direction: column;
    padding: 20px;
  }
  .benefits {
    padding: 30px;
    height: 150vh !important;
    border-radius: 5px;
  }
}
